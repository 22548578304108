<template>
  <div class="createConsultaion">
    <!-- <navbar /> -->

    <div v-if="show" class="loaderCart">
      <img src="../assets/debit-card.png" alt="" />
      <p class="mt-3 mb-3 fw-bold fs-4">جاري التحويل الى صفحة الدفع</p>
      <pulse-loader
        :loading="loading"
        :color="color"
        :size="size"
      ></pulse-loader>
    </div>

    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="user-info">
              <i class="el-icon-right"></i>

              <div class="user-img">
                <img
                  v-if="single_consultion.user_info"
                  :src="single_consultion.user_info.image"
                  alt=""
                />
              </div>
              <h3 v-if="single_consultion.user_info">
                {{ single_consultion.user_info.name }}
              </h3>
              <p v-html="single_consultion.description"></p>
              <div class="instruct">
                <i class="el-icon-time"></i>
                <span
                  v-if="single_consultion.hour && single_consultion.hour > 0"
                >
                  hr {{ single_consultion.hour }}</span
                >
                <span
                  v-if="
                    single_consultion.minutes && single_consultion.minutes > 0
                  "
                >
                  min {{ single_consultion.minutes }}</span
                >
              </div>

              <div class="instruct">
                <i class="el-icon-video-camera-solid"></i>
                <!-- <span>يتم توفير تفاصيل مؤتمرات الويب عند التأكيد</span> -->
                <span v-if="single_consultion.place">
                  على منصة {{ single_consultion.place.title }}</span
                >
              </div>

              <div class="instruct">
                <i class="el-icon-bank-card"></i>
                <span v-if="single_consultion.price"
                  >{{ single_consultion.price }} USD</span
                >
              </div>

              <div class="instruct">
                <i class="el-icon-date"></i>
                <!-- <span>20:00 - 22:00, Wednesday, August 31, 2022</span> -->
                <span>اوقات الاستشارة:</span>
                <li
                  class="mt-1 mb-0 fw-normal"
                  v-for="item in single_consultion.date"
                  :key="item.id"
                >
                  يوم {{ item.day }} يبدا من {{ item.start_at }} الى
                  {{ item.end_at }}
                </li>
              </div>

              <div class="instruct">
                <i class="el-icon-location"></i>
                <span>أسيا / غزة </span>
              </div>
            </div>
          </div>

          <div class="col-lg-7">
            <!-- <el-calendar  v-model="value8" :range="['2023-02-06', '2023-02-26']">
              </el-calendar> -->

            <!-- <vue-cal
                    selected-date="2018-11-19"
                    xsmall
                    :time-from="10 * 60"
                    :disable-views="['day']"
                    events-count-on-year-view
                    active-view="month"
                    :events="events"
                    maxDate="2018-11-26"
                    minDate="2018-11-5"
                    :hideTitleBar="true"
                    onEventClick="CalFun"
                    >
                  </vue-cal> -->

            <!-- <vue-meeting-selector
      class="simple-example__meeting-selector"
      v-model="meeting"
      :date="date"
      :class-names="classNames"
      :meetings-days="meetingsDays"
      @next-date="nextDate"
      @previous-date="previousDate"
    />
    <p>meeting Selected: {{ meeting ? meeting : "No Meeting selected" }}</p> -->


            <div
              v-if="loading"
              class="d-flex justify-content-center align-items-center"
              style="margin-top: 40px"
            >
              <span
                class="d-block"
                style="
                  font-weight: 700;
                  font-size: 26px;
                  margin-top: -15px;
                  margin-left: 18px;
                "
              >
                جاري التحميل
              </span>
              <div class="">
                <pulse-loader :color="color" :size="size"></pulse-loader>
              </div>
            </div>

            


            <div class="" v-else >
                          <h5 
              class="mt-4 me-3 text-end"
              style="font-size: 20px; font-weight: 700; margin-bottom: -30px"
            >
              حدد موعد
            </h5>
              <vue-meeting-selector
              
              class="simple-example__meeting-selector"
              style=""
              v-model="meeting"
              :date="calData"
              :class-names="classNames"
              :meetings-days="meetingsDays"
              :multi="true"
              @next-date="nextDate"
              @previous-date="previousDate"
              @change="MetChange"
            />

            <p style="font-size: 14px" class="mt-2">
              الموعد المختار : {{ meeting ? meeting : "No Meeting selected" }}
            </p>
            </div>


            <!-- <p style="color: red; font-size: 14px; text-align: left">
              {{ meetMsg }}
            </p> -->
            <div class="form">
              <h3 class="mt-4 mb-3">أدخل بياناتك</h3>
              <span class="arabi-mark">صناعة المحتوى</span>

              <el-form
                ref="form"
                :model="form"
                label-width="120px"
                label-position="top"
              >
                <el-form-item label="الاسم بالكامل  ">
                  <el-input
                    v-model="Username"
                    placeholder="ادخل الاسم  "
                    disabled
                  ></el-input>
                </el-form-item>

                <el-form-item label="البريد الالكتروني ">
                  <el-input
                    v-model="email"
                    placeholder=" ادخل الايميل"
                    disabled
                  ></el-input>
                </el-form-item>

                <a
                  class="btn btn-primary mb-2 mt-2"
                  @click="showEmail = !showEmail"
                  >اضف ضيف</a
                >

                <el-form-item v-if="showEmail">
                  <el-input
                    v-model="emailGuest"
                    placeholder=" ادخل بريد الضيف"
                  ></el-input>
                </el-form-item>

                <p>يرجى مشاركة أي شيء من شأنه أن يساعد في التحضير لاجتماعنا.</p>
                <el-form-item label="">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 10, maxRows: 14 }"
                    v-model="form.desc"
                  ></el-input>
                </el-form-item>

                <!-- <p>حدد الوقت والتاريخ</p>
                <el-select v-model="form.day" placeholder="Select">
                  <el-option
                    v-for="item in days"
                    :key="item.id"
                    :label="item.day"
                    :value="item.day"
                  >
                  </el-option>
                </el-select>

                <p class="mt-4 mb-2">حدد الوقت</p>

               

                <el-form-item label="من  " class="form-item">
                  <el-time-select
                    v-model="form.startTime"
                    :picker-options="{
                      start: start_at,
                      step: stepTime,
                      end: end_at,
                    }"
                    placeholder="Select time"
                  >
                  </el-time-select>
                </el-form-item>
                <el-form-item label="الى  " class="form-item">
                  <el-time-select
                    v-model="form.endTime"
                    :picker-options="{
                      start: start_at,
                      step: stepTime,
                      end: end_at,
                    }"
                    placeholder="Select time"
                  >
                  </el-time-select>
                </el-form-item> -->
                <h5 class="mt-4" style="font-size: 20px; font-weight: 700">
                  معلومات الدفع
                </h5>
                <div class="infoPay">
                  <h6>السعر</h6>
                  <span v-if="single_consultion"
                    >{{ single_consultion.price }} <span>usd</span></span
                  >
                  <!-- <el-button
                    type="primary"
                    icon="el-icon-bank-card"

                    @click.prevent="pay"
                  >
                  ادفع
                  </el-button> -->
                </div>

                <div class="infoBank infoPay mt-3" v-if="bank">
                  <h6 class="mb-4">الدفع عن طريق البنك</h6>
                  <el-form>
                    <div class="row">
                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-model="username"
                            placeholder="ادخل الاسم  "
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-cardformat:formatCardNumber
                            v-model="username"
                            placeholder="رقم الحساب"
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input v-model="username" placeholder="الايبان">
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input v-model="username" placeholder="Swift Code">
                          </el-input>
                        </el-form-item>
                      </div>
                    </div>

                    <el-button type="primary">دفع</el-button>
                  </el-form>
                </div>

                <div v-if="payoneer" class="infoBank infoPay mt-3">
                  <h6 class="mb-4">الدفع عن طريق payoneer</h6>
                  <el-form>
                    <div class="row">
                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-model="username"
                            placeholder="ادخل الاسم  "
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-cardformat:formatCardNumber
                            v-model="username"
                            placeholder="رقم الحساب"
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-model="username"
                            placeholder="رقم التلفون"
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-cardformat:formatCardCVC
                            v-model="username"
                            placeholder="CVV"
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-cardformat:formatCardExpiry
                            v-model="username"
                            placeholder="Expire Date"
                          >
                          </el-input>
                        </el-form-item>
                      </div>
                    </div>

                    <el-button type="primary">دفع</el-button>
                  </el-form>
                </div>

                <div v-if="mada" class="infoBank infoPay mt-3">
                  <h6 class="mb-4">الدفع عن طريق mada</h6>
                  <el-form>
                    <div class="row">
                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-model="username"
                            placeholder="ادخل الاسم  "
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-cardformat:formatCardNumber
                            v-model="username"
                            placeholder="رقم الحساب"
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-model="username"
                            placeholder="رقم التلفون"
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-cardformat:formatCardCVC
                            v-model="username"
                            placeholder="CVV"
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-cardformat:formatCardExpiry
                            v-model="username"
                            placeholder="Expire Date"
                          >
                          </el-input>
                        </el-form-item>
                      </div>
                    </div>

                    <el-button type="primary">دفع</el-button>
                  </el-form>
                </div>

                <div v-if="Western" class="infoBank infoPay mt-3">
                  <h6 class="mb-4">الدفع عن طريق Western Union</h6>
                  <el-form>
                    <div class="row">
                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-model="username"
                            placeholder="ادخل الاسم  "
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-cardformat:formatCardNumber
                            v-model="username"
                            placeholder="رقم الحساب"
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-model="username"
                            placeholder="رقم التلفون"
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-cardformat:formatCardCVC
                            v-model="username"
                            placeholder="CVV"
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-cardformat:formatCardExpiry
                            v-model="username"
                            placeholder="Expire Date"
                          >
                          </el-input>
                        </el-form-item>
                      </div>
                    </div>

                    <el-button type="primary">دفع</el-button>
                  </el-form>
                </div>

                <div v-if="applePay" class="infoBank infoPay mt-3">
                  <h6 class="mb-4">الدفع عن طريق apple pay</h6>
                  <el-form>
                    <div class="row">
                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-model="username"
                            placeholder="ادخل الاسم  "
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-cardformat:formatCardNumber
                            v-model="username"
                            placeholder="رقم الحساب"
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-model="username"
                            placeholder="رقم التلفون"
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-cardformat:formatCardCVC
                            v-model="username"
                            placeholder="CVV"
                          >
                          </el-input>
                        </el-form-item>
                      </div>

                      <div class="col-6">
                        <el-form-item>
                          <el-input
                            v-cardformat:formatCardExpiry
                            v-model="username"
                            placeholder="Expire Date"
                          >
                          </el-input>
                        </el-form-item>
                      </div>
                    </div>

                    <el-button type="primary">دفع</el-button>
                  </el-form>
                </div>

                <el-button type="primary" @click.prevent="BookConsultion"
                  >تأكيد
                </el-button>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from "vue";
// import pulseLoader from 'vue-spinner/src/PulseLoader.vue'
import VueMeetingSelector from "vue-meeting-selector";

// import data from "../data.json";
// import data2 from "../data2.json";

import { Alert } from "bootstrap";

import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  components: {
    VueMeetingSelector,
    PulseLoader,
  },

  data() {
    return {
      date: new Date(),
      meetingsDays: [],
      meeting: { date: "" },
      showEmail: false,
      emailGuest: [],

      show: false,
      options: [
        {
          value: "Wednesday ",
          label: "Wednesday ",
        },
      ],
      value: "",
      value1: "",
      radio1: "red",
      Username: localStorage.getItem("name"),
      email: localStorage.getItem("email"),
      form: {
        name: "",
        day: "",
        startTime: " ",
        endTime: " ",
        desc: "",
      },

      single_consultion: "",
      start_at: undefined,
      end_at: undefined,
      days: undefined,
      stepTime: undefined,

      checkOut: undefined,

      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      Western: false,
      bank: false,
      payoneer: false,
      applePay: false,
      mada: false,

      divideArr: [],
      start: "08:00",
      end: "14:00",

      divideArr2: [],
      start2: "08:30",
      end2: "10:30",

      consId: "",

      calData: [],
      nbDaysToDisplay: 5,
      meetMsg: "",
      loading: true,
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.meeting = this.meeting.date;

    // 1 hr

    const res = this.end.substr(0, 2) - this.start.substr(0, 2);

    for (let index = 0; index <= res; index++) {
      this.divideArr.push(parseInt(this.start.substr(0, 2)) + index + ":00");
    }

    console.log(this.start + " " + this.end);

    console.log(this.divideArr);

    // 30 min

    //  start2:'08:30'
    //   end2:'10:30'

    const res2 = this.end2.substr(0, 2) - this.start2.substr(0, 2);
    const ele1 = this.start2.substr(0, 2);
    const ele2 = this.start2.substr(3, 5);

    for (let index = 0; index <= res2; index++) {
      if (ele2 == 30) {
        this.divideArr2.push(
          parseInt(this.start2.substr(0, 2)) + index + ":30"
        );
      } else if (ele2 < 30) {
        this.divideArr2.push(
          parseInt(this.start2.substr(0, 2)) + index + ":00"
        );
      }
    }

    // console.log(this.start2 +" "+ this.end2)

    console.log(this.divideArr2);

    // alert(localStorage.getItem('userEmail'))

    // this.username = localStorage.getItem("d");
    // this.email = localStorage.getItem("userEmail");
    Vue.axios
      .get(
        `https://dashboard.arabicreators.com/api/single_consultion/${this.$route.params.mention}/${this.$route.params.url}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log("single_consultion" + res);
        this.single_consultion = res.data.data;

        this.consId = res.data.data.id;

        Vue.axios
          .get(
            `https://dashboard.arabicreators.com/api/get_json/${this.consId}`,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.calData = res.data;
            this.meetingsDays = this.calData;
            this.loading = false;
            console.log("data " + this.calData);
          });

        this.start_at = this.single_consultion.date[0].start_at;
        this.end_at = this.single_consultion.date[0].end_at;
        this.days = this.single_consultion.date;

        if (this.single_consultion.minutes == "30د") {
          this.stepTime = "00:30";
        } else if (this.single_consultion.minutes == "60د") {
          this.stepTime = "00:60";
        } else if (this.single_consultion.minutes == "90د") {
          this.stepTime = "00:90";
        } else if (this.single_consultion.minutes == "120د") {
          this.stepTime = "00:120";
        }

        if (this.single_consultion.payment.title == "payoneer") {
          this.payoneer = true;
        } else if (this.single_consultion.payment.title == "حساب بنكي") {
          this.bank = true;
        } else if (this.single_consultion.payment.title == "apple pay") {
          this.applePay = true;
        } else if (this.single_consultion.payment.title == "mada") {
          this.mada = true;
        } else if (this.single_consultion.payment.title == "Western") {
          this.Western = true;
        }
        // alert(this.start_at)

        // this.show=! this.show
      });
  },

  methods: {
    MetChange() {
      // alert(new Date(this.meeting.date).toDateString())

      // this.meeting=new Date(this.meeting.date).toString()

      this.meeting = this.meeting.date;
      // alert()
    },

    async nextDate() {
      console.log("nextDate");
    },
    // @click on button-left
    async previousDate() {
      console.log("previousDate");
    },

    BookConsultion() {
      if (this.meeting) {
        const data = new FormData();

        data.append("consult_id", this.consId);
        data.append("date", this.meeting);
        data.append("start_at", this.form.startTime);
        data.append("end_at", this.form.endTime);
        data.append("email", this.email);
        data.append("name", this.Username);
        data.append("note", this.form.desc);
        data.append("emails", this.emailGuest);

        // this.show = !this.show;
        window.scrollTo(0, 300);
        Vue.axios
          .post(`https://dashboard.arabicreators.com/api/checkout_cons`, data, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            this.checkOut = res.data.data.link;
            console.log(this.checkOut);

            this.$message({
              message: "تم حجز الاستشارة بنجاح",
              type: "success",
            });

            this.$router.push(`/`);

            // window.location = `${this.checkOut}`;

            // window.open(`${this.checkOut}`, "_blank");

            // this.show = !this.show;

            if (res.data.message == "added") {
              this.$message({
                message: "تم الحجز",
                type: "success",
              });
            } else if (res.data.code == 400) {
              if (res.data.message == "you need to login") {
                this.$message.error("تحتاج الى تسجيل الدخول");
              }
            }
          });
      } else if (!this.meeting) {
        // this.meetMsg="رجاءً قم بتحديد موعد للاستشارة"
        this.$message.error("رجاءً قم بتحديد موعد للاستشارة");
        window.scrollTo(0, 300);
      }
    },

    pay() {
      // alert(this.checkOut)
    },

    // this.$store.dispatch('user',res.data.token)

    onSubmit() {
      console.log("submit!");
    },
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
  },

  async created() {
    // alert(this.meetingsDays)
  },
};
</script>


<style lang="scss">
.loaderCart {
  .v-spinner {
    margin-top: 0 !important;
  }
}
</style>


<style lang="scss">
.loaderCart {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-top: 15%;
  flex-direction: column;
  z-index: 50;

  img {
    margin-bottom: 50px;
  }
}
.createConsultaion {
  font-family: "Tajawal", sans-serif;

  direction: rtl;
  background-color: #f0efef;
  padding-bottom: 50px;

  .content {
    padding: 50px;
    background-color: white;
    max-width: 70%;
    margin: 50px auto 0px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    .arabi-mark {
      width: 240px;
      font-size: 20px;
      font-weight: 700;
      color: white;
      background: var(--darkColor) !important;
      position: absolute;
      left: -48px;
      top: 37px;
      padding: 10px 20px;
      transform: rotate(-43deg);
      text-align: center;
    }

    .form {
      text-align: right;
      max-width: 90%;
      // border-right: 1px solid #bcbbbb;
      border-right: none;
      padding-right: 40px;
      .el-form-item__label {
        display: block;
        text-align: right;
        padding-bottom: 0;
        font-weight: 700;
      }

      h3 {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 10px;
      }

      .el-select {
        width: 100%;
      }

      .el-input__inner {
        height: 50px;
        border-radius: 10px;
      }

      .el-button-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
      }

      .el-button-group > .el-button {
        border-radius: 50%;
        width: 50px;
        height: 50px;
      }

      .el-button--primary {
        display: block;
        width: 100%;
        background: var(--darkColor);
        border-radius: 10px;
        color: white;
        height: 50px;
        margin-bottom: 10px;
        margin-top: 40px;
        border: none;
      }

      h5 {
        margin-bottom: 15px;
        font-size: 18px;
      }

      .infoPay {
        border: 1px solid #dcdfe6;
        padding: 10px;
        border-radius: 10px;

        h6 {
          font-weight: 700;
        }

        span {
        }

        .el-button--primary {
          display: block;
          width: 100%;
          background: linear-gradient(
            239.11deg,
            #44bcd6 -31.67%,
            #567a46 192.97%
          ) !important;
          border-radius: 10px;
          color: white;
          height: 40px !important;
          margin-bottom: 10px;
          margin-top: 20px !important;
          border: none;
          line-height: 12px !important;
        }
      }
    }

    .user-info {
      text-align: right;

      .user-img {
        margin-bottom: 15px;
        border: 1px solid #d1cece;
        img {
        }
      }

      h3 {
        margin-bottom: 10px;
        opacity: 0.5;
        font-size: 22px;
        color: #000;
      }

      p {
        margin-bottom: 40px;
        font-weight: 700;
      }

      .instruct {
        margin-bottom: 15px;

        span {
          margin-right: 10px;
        }
      }

      .el-icon-right {
        margin-bottom: 20px;
        color: var(--darkColor);
        font-size: 25px;
        font-weight: 700;
        cursor: pointer;
      }

      .el-icon-time,
      .el-icon-video-camera-solid,
      .el-icon-bank-card,
      .el-icon-date,
      .el-icon-location {
        font-size: 22px;
        color: #8e8b8b;
      }
    }
  }
}

.form-item {
  width: 48%;
  float: right;

  .el-input {
    width: 100% !important;
  }
}

.meeting-selector {
  direction: ltr;
  margin-top: 50px;
}

.meeting-selector .tab__days {
  overflow: hidden;
  width: 100%;
  overflow-x: scroll;
}

.meeting__button {
  // background-color: #72bffc8a;
  color: #5b6164;
  cursor: pointer;
  border: none;
  font-weight: 600 !important;
  margin: 5px 0 !important ;
  padding: 2px 2px !important;
  font-size: 15px !important;
  border-radius: 50% !important;
  width: 50px !important ;
  height: 50px !important;
}

.day__title {
  font-size: 16px;
  font-weight: 700;
  margin-right: 20px;
  text-transform: capitalize;
}

// .vms-icon-right:before,.vms-icon-left:before,.vms-icon-down:before,.vms-icon-up:before{
//   color: #022D47;
// }

.meeting-selector .tab__days::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.meeting-selector .tab__days::-webkit-scrollbar-thumb {
  background: #98a0a5 !important;
  border-radius: 10px;
  height: 6px !important;
}
</style>